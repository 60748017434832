import {useEffect, useState} from 'react';

// import { PrimaryButton } from '@shared/buttons';
// import { Modal } from '@shared/controls/Modal';
import {ListingFormComponent} from '@shared/forms/ListingFormComponent';

import {
  Step1,
  Step2,
  // Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
} from './steps';
import {
  createProperty,
  getSingleListingProperty,
  // getSingleProperty,
  updateProperty,
} from '@services/krent.service';
import ktoast from '@services/toast.service';

import {useLocation} from 'react-router-dom';
// import { PropertyListResponse } from '@interfaces/properties.interface';

export const ListShortlet = () => {
  // const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  // const [showModal, setShowModal] = useState<boolean>(false);
  const location: any = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editID = searchParams.get('id');

  const [index, setIndex] = useState<number>(location?.state?.index || 0);
  // const [editData, setEditData] = useState<PropertyListResponse>();
  const [editLoading, setEditLoading] = useState<boolean>(false);

  const [propertyName, setPropertyName] = useState<string>('');
  const [propertyDesc, setPropertyDesc] = useState<string>('');
  const [propertyType, setPropertyType] = useState<string>('');
  const [propertyCategory, setPropertyCategory] = useState<string>('');
  const [spaceType, setSpaceType] = useState<string>(
    '64b5a3d3bc787500113886bd'
  );
  const [intent, setIntent] = useState<string>('shortlet');
  const [safetyFeatures, setSafetyFeatures] = useState<any>([]);
  const [amenities, setAmenities] = useState<any>([]);
  const [guests, setGuests] = useState<number>();
  const [bedrooms, setBedrooms] = useState<number>();
  const [beds, setBeds] = useState<number>();
  const [bathrooms, setBathrooms] = useState<number>();
  const [floors, setFloors] = useState<number>();
  const [garages, setGarages] = useState<number>();
  const [size, setSize] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [cautionFee, setCautionFee] = useState<number>();
  const [address, setAddress] = useState<string>();
  const [apartment, setApartment] = useState<string>();
  const [city, setCity] = useState<string>();
  const [state, setState] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [tourUrl, setTourUrl] = useState<string>();
  const [tourIntrest, setTourIntrest] = useState<boolean>(false);
  const [affiliation, setAffiliation] = useState<string>();
  const [propertyCreated, setPropertyCreated] = useState<boolean>();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [flood_meter, setFlood_meter] = useState<string>();
  const [neighbour_story, setNeighbour_story] = useState<boolean>(false);
  const [geo, setGeo] = useState<[]>();
  const [rules, setRules] = useState<string>();
  const [toilets, setToilets] = useState<[]>();
  const [virtual_tour_date_request, setVirtual_tour_date_request] =
    useState<any>();
  const [cancellationPolicy, setCancellationPolicy] = useState<string>('');
  const [checkInRules, setCheckInRules] = useState<string>('');
  // const [propertySuccessModal, setPropertySuccessModal] = useState<string>('');
  const [propertyId, setPropertyId] = useState('');

  const onPrev = () => {
    setIndex(index - 1);
  };

  const onNext = () => {
    setIndex(index + 1);
  };
  const handleCreate = () => {
    setLoading(true);

    const payloads = {
      title: propertyName,
      summary: propertyDesc,
      category: '61288274d0c4166348e3dec8',
      // category: propertyCategory,
      kind: propertyType,
      space: spaceType,
      address,
      location2: {
        apartment,
        city,
        state,
        country,
        geo,
        formatted_address: address,
      },
      price: price,
      cautionFee,
      currency: 'NGN',
      description: propertyDesc,
      intent,
      cycle: 'daily',
      guests: guests,
      beds: beds,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      virtual_tour_url: tourUrl,
      video_url: videoUrl,
      affiliation,
      amenities,
      flood_meter,
      neighbour_story,
      rules,
      toilets,
      virtual_tour_date_request,
      cancellation_policy: cancellationPolicy,
      checkIn_rules: checkInRules,
    };

    if (editID) {
      updateProperty(editID, payloads)
        .then(() => {
          setPropertyId(editID);
          setPropertyCreated(true);
          setShowSuccessModal(true);
        })
        .catch((err) => {
          ktoast.error(err.errors[0].message);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    createProperty(payloads)
      .then((res: any) => {
        setPropertyId(res?.data.id);
        setPropertyCreated(true);
        setShowSuccessModal(true);
        // onNext();
      })
      .catch((err) => {
        console.log(err);

        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const showM = () => {};
  useEffect(() => {
    if (editID) {
      setEditLoading(true);
      let abortController = new AbortController();
      getSingleListingProperty(editID, abortController.signal)
        .then((res: any) => {
          // setEditData(res.data);
          setPropertyName(res.data?.title);
          setPropertyDesc(res.data?.summary);
          setPropertyType(res.data?.kind);
          setPropertyCategory(res.data?.category);
          setSpaceType('64b5a3d3bc787500113886bd');
          setIntent(res.data.intent);
          setAddress(res.data?.location2.formatted_address);
          setApartment(res.data?.location2.apartment);
          setCity(res.data?.location2.city);
          setState(res.data?.location2.state);
          setCountry(res.data?.location2.country);
          setGeo(res.data?.location2.geo);
          setPrice(res.data?.price);
          setCautionFee(res.data?.cautionFee);
          setPropertyDesc(res.data?.description);
          setGuests(res.data?.guests);
          setBeds(res.data?.beds);
          setBedrooms(res.data?.bedrooms);
          setBathrooms(res.data?.bathrooms);
          setVideoUrl(res.data?.video_url);
          setTourUrl(res.data?.virtual_tour_url);
          setAffiliation(res.data?.affiliation);
          setAmenities(res.data?.amenities);
          setFlood_meter(res.data?.flood_meter);
          setNeighbour_story(res.data?.neighbour_story);
          setRules(res.data?.rules);
          setToilets(res.data?.toilets);
          setVirtual_tour_date_request(res.data?.virtual_tour_date_request);
          setEditLoading(false);
          setCancellationPolicy(res.data?.cancellation_policy);
          setCheckInRules(res.data?.checkIn_rules);
        })
        .catch((err) => {
          console.log(err.errors[0].message);
          setEditLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [editID]);

  if (editLoading) {
    return <h3>Loading...</h3>;
  }

  return (
    <div
      className='border border-round'
      style={{
        maxHeight: '90vh',
        overflow: 'scroll',
        paddingBottom: '3rem',
      }}
    >
      <ListingFormComponent index={index}>
        {/* setShowModal={setShowModal} */}
        <Step1
          propertyName={propertyName}
          setPropertyName={setPropertyName}
          propertyDesc={propertyDesc}
          setPropertyDesc={setPropertyDesc}
          onNext={onNext}
        ></Step1>
        <Step2
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          propertyCategory={propertyCategory}
          setPropertyCategory={setPropertyCategory}
          intent={intent}
          setIntent={setIntent}
          affiliation={affiliation}
          setAffiliation={setAffiliation}
          onPrev={onPrev}
          onNext={onNext}
        ></Step2>
        {/* <Step3
          spaceType={spaceType}
          setSpaceType={setSpaceType}
          onPrev={onPrev}
          onNext={onNext}
        ></Step3> */}
        <Step4
          guests={guests}
          setGuests={setGuests}
          bedrooms={bedrooms}
          setBedrooms={setBedrooms}
          bathrooms={bathrooms}
          setBathrooms={setBathrooms}
          floors={floors}
          setFloors={setFloors}
          garages={garages}
          setGarages={setGarages}
          beds={beds}
          setBeds={setBeds}
          setToilets={setToilets}
          toilets={toilets}
          setRules={setRules}
          rules={rules}
          cancellationPolicy={cancellationPolicy}
          setCancellationPolicy={setCancellationPolicy}
          checkInRules={checkInRules}
          setCheckInRules={setCheckInRules}
          size={size}
          setSize={setSize}
          onPrev={onPrev}
          onNext={onNext}
        ></Step4>
        <Step5
          price={price}
          setPrice={setPrice}
          cautionFee={cautionFee}
          setCautionFee={setCautionFee}
          onPrev={onPrev}
          onNext={onNext}
        ></Step5>
        <Step6
          safetyFeatures={safetyFeatures}
          setSafetyFeatures={setSafetyFeatures}
          amenities={amenities}
          setAmenities={setAmenities}
          onPrev={onPrev}
          onNext={onNext}
        ></Step6>
        <Step7
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          country={country}
          setCountry={setCountry}
          apartment={apartment}
          setApartment={setApartment}
          onPrev={onPrev}
          onNext={onNext}
          loading={loading}
          setLoading={setLoading}
          handleCreate={handleCreate}
          showSuccessModal={showSuccessModal}
          setShowSuccessModal={setShowSuccessModal}
          setNeighbour_story={setNeighbour_story}
          setFlood_meter={setFlood_meter}
          flood_meter={flood_meter}
          setGeo={setGeo}
          editID={editID}
        ></Step7>
        <Step8
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          tourUrl={tourUrl}
          setTourUrl={setTourUrl}
          tourIntrest={tourIntrest}
          setTourIntrest={setTourIntrest}
          setLoading={setLoading}
          loading={loading}
          onPrev={onPrev}
          propertyId={propertyId}
          propertyCreated={propertyCreated}
          affiliation={affiliation}
          virtualTourDate={virtual_tour_date_request}
          setVirtualTourDate={setVirtual_tour_date_request}
        ></Step8>
      </ListingFormComponent>

      {/* {showModal && (
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <div className='col px-4'>
            <h6 className='fw-bold fs-20'>
              Are you ready to publish this property?
            </h6>
            <p className='light-text'>
              Once you publish this property, Krent will review your listing and
              your listing would go live once it is approved.
            </p>

            <div className='row'>
              <div className='col-6'>
                <PrimaryButton
                  text='Publish'
                  loading={loading}
                  type='primary'
                  click={showM}
                />
              </div>
              <div className='col-6'>
                <PrimaryButton
                  text='Do this later'
                  type='outline'
                  click={() => setShowModal(false)}
                />
              </div>
            </div>
          </div>
        </Modal>
      )} */}
    </div>
  );
};
