// import {useSessionStorage} from '../../../hooks/useSessionStorage';
import {cancelTransaction, reportIncident} from '@services/krent.service';
import ktoast from '@services/toast.service';
import {PrimaryButton} from '@shared/buttons';

// import {Modal} from '@shared/controls/Modal';

// import {InputComponent} from '@shared/forms';
import {useState} from 'react';
import Modal from 'react-responsive-modal';

import {Link} from 'react-router-dom';

const TransactionTableData = ({data, getTransactions}: any) => {
  // const [user] = useSessionStorage('user', {});

  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const [cancellationReason, setCancellationReason] = useState<string>('');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>('');
  const [openIncident, setOpenIncident] = useState(false);
  const [incident, setIncident] = useState('');
  // const navigate = useNavigate();
  const handleCancelTransaction = (reason: string) => {
    setLoading(true);

    cancelTransaction(transactionId, reason)
      .then(() => {
        // navigate('/explore-listings');
        setCancellationReason('');
        setLoading(false);
        ktoast.success('Transactions successfully cancelled');
      })
      .catch((err) => {
        console.log(err);
        ktoast.error(err?.errors[0]?.message);

        setLoading(false);
      })
      .finally(() => {
        getTransactions();
      });
  };
  // const sortedData = data
  //   .slice()
  //   .sort(
  //     (a: any, b: any) =>
  //       a.updatedAt?.seconds ||
  //       a.createdAt.seconds - b.updatedAt.seconds ||
  //       b.createdAt.seconds
  //   );
  const handleSubmitIncident = (e: any) => {
    e.preventDefault();
    setLoading(true);
    reportIncident(transactionId, incident)
      .then((resp: any) => {
        ktoast.success(resp?.message);
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <tbody className='list fs-base'>
      {data.map((info: any) => {
        const {
          arrival_date,
          createdAt,
          updatedAt,
          departure_date,
          inspected,
          // inspection_date,
          id,
          owner,
          originalTransaction,
          type,
          // property_avalability_status,
          status,
          listing,
        } = info;

        return (
          <tr key={id}>
            {/* <td>
              <div className='form-check'>
                <input
                  className='form-check-input list-checkbox'
                  id='listCheckboxOne'
                  type='checkbox'
                />
                <label
                  className='form-check-label'
                  htmlFor='listCheckboxOne'
                ></label>
              </div>
            </td> */}
            <td>
              {/* <Link to={`/transactions/${id}`}> */}
              {/* {listing?.title.length > 0 && listing?.title.slice(0, 20) + '...'} */}
              {/* </Link> */}
              <Link
                to={`/property/${listing?.slug}/`}
                state={{id: listing?.slug}}
              >
                {listing?.title.length > 0 &&
                  listing?.title.slice(0, 20) + '...'}
              </Link>
            </td>
            <td>
              <span className='item-intent'>{owner?.preferredName}</span>
            </td>
            <td>
              <span className='item-intent'>{listing?.intent}</span>
            </td>
            <td>
              <span className='item-bedrooms'>
                {/* {moment(arrival_date).format('MMM Do YY')} */}

                {listing?.intent === 'sale'
                  ? 'N/A'
                  : new Date(arrival_date?.toLocaleString()).toLocaleDateString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      }
                    )}
              </span>
            </td>
            <td>
              <span className='item-location'>
                {listing?.intent === 'sale'
                  ? 'N/A'
                  : new Date(
                      departure_date?.toLocaleString()
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
              </span>
            </td>
            {/* <td>
              <span className='item-cycle'>
                {moment(inspection_date).format('MMM Do YY')}
              </span>
            </td> */}
            {/* <td>
              <span className='item-user'>{property_avalability_status}</span>
            </td> */}
            <td>
              <span className='item-price'>
                {listing?.intent === 'shortlet' ? (
                  <span className='badge bg-info'> N/A</span>
                ) : inspected === false ? (
                  <span className='badge bg-danger'> NO</span>
                ) : (
                  <span className='badge bg-success'> YES </span>
                )}
              </span>
            </td>
            <td>
              <span className='item-price'>
                {new Date(updatedAt?.toLocaleString()).toLocaleDateString(
                  'en-US',
                  {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  }
                )}
              </span>
            </td>
            <td>
              <span className='item-createdAt text-reset'>
                {new Date(createdAt?.toLocaleString()).toLocaleDateString(
                  'en-US',
                  {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  }
                )}
              </span>
            </td>
            {/* <span className='item-createdAt text-reset'>{status}</span> */}
            <td className=''>
              {status === 'customer-accepted' ? (
                <div className='badge bg-primary'>Accepted</div>
              ) : status === 'completed' ? (
                <div className='badge bg-success'>Completed</div>
              ) : status === 'progress' ? (
                <div className='badge bg-warning'>In Progress</div>
              ) : (
                <div className='badge bg-danger'>{status}</div>
              )}
            </td>
            <td className=''>
              {type === 'initial' ? (
                <Link to={`/message/${id}`} className=' fs-13'>
                  Proceed
                </Link>
              ) : (
                <Link to={`/message/${originalTransaction}`} className=' fs-13'>
                  Proceed
                </Link>
              )}
            </td>
            {/* <td className=''>
              <button
                className='dropdown-item btn btn-link fs-13 text-danger'
                onClick={() => {
                  setShowCancelModal(true);
                  setTransactionId(id);
                }}
              >
                Close Transaction
              </button>
            </td> */}
            <td className=''>
              <div className='dropdown'>
                <button
                  className=' dropdown-ellipsis btn btn-link'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  type='button'
                >
                  <i className='fa-solid fa-ellipsis-vertical text-primary'></i>
                </button>
                <div className='dropdown-menu dropdown-menu-end'>
                  {type === 'initial' ? (
                    <Link to={`/message/${id}`} className=' fs-13'>
                      Manage Booking
                    </Link>
                  ) : (
                    <Link
                      to={`/message/${originalTransaction}`}
                      className=' fs-13'
                    >
                      Proceed
                    </Link>
                  )}
                  <button
                    className='dropdown-item btn btn-link fs-13'
                    onClick={() => {
                      setShowCancelModal(true);
                      setTransactionId(id);
                    }}
                  >
                    Close Transaction
                  </button>
                  {listing.intent === 'long lease' &&
                    status === 'completed' && (
                      <button
                        className='dropdown-item btn btn-link fs-13'
                        onClick={() => {
                          setOpenIncident(true);
                          setTransactionId(id);
                        }}
                      >
                        Report a problem
                      </button>
                    )}
                </div>
              </div>
            </td>
          </tr>
        );
      })}
      {/* <Modal
        show={showCancelModal}
        onClose={() => {
          setShowCancelModal(false);
          getTransactions();
        }}
        // dismissible={false}
      > */}

      <Modal
        center
        open={showCancelModal}
        onClose={() => {
          setShowCancelModal(false);
          getTransactions();
        }}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
          modal: 'customModal',
        }}
        animationDuration={500}
        aria-labelledby='summary-title'
        aria-describedby='summary-description'
      >
        <div className='col-12 mt-5 '>
          <h6 className='fs-12 fw-bold h-modal-color'>Cancel Transaction</h6>
          <p className='fs-14 light-text' id='summary-description'>
            We are sorry you have to cancel this transaction. In other to serve
            you better please share with us the reason why you are cancelling
            this transaction.
          </p>
          <form
            className='mt-4 mt-md-3'
            onSubmit={(e) => {
              e.preventDefault();
              handleCancelTransaction(cancellationReason);
            }}
          >
            <div className='col mb-4'>
              {/* <InputComponent
                label=''
                icon=''
                placeholder='Please state your reason'
                type='text'
                value={cancellationReason}
                change={(e: any) => setCancellationReason(e.target.value)}
                required
              /> */}
              <textarea
                className='form-control border-round-xl py-3 px-4 fs-14 bg-purple'
                required
                placeholder='Please state your reason'
                rows={4}
                maxLength={500}
                value={cancellationReason}
                onChange={(e: any) => setCancellationReason(e.target.value)}
              ></textarea>
            </div>

            <div className='col text-center'>
              <PrimaryButton type='primary' text='Submit' loading={loading} />
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        open={openIncident}
        center
        onClose={() => setOpenIncident(false)}
        // classNames={{ modal: 'w-300 rounded' }}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
          modal: 'customModal',
        }}
        animationDuration={800}
      >
        <div className='col-10 mt-3'>
          <div className='col-12'>
            <h6 className='fs-12 fw-bold h-modal-color'>Report an Incident</h6>
            <p className='fs-14 light-text' id='summary-description'>
              If you have any complaint or encounter any challenges with the
              Agent or Landlord please report it we will be happy to help
              resolve it.
            </p>
            <form className='mt-4 mt-md-3' onSubmit={handleSubmitIncident}>
              <div className='col mb-4'>
                {/* <InputComponent
                  label=''
                  icon=''
                  placeholder='Please state your complaint'
                  type='text'
                  value={incident}
                  change={(e: any) => setIncident(e.target.value)}
                  required
                /> */}
                <textarea
                  name='incident'
                  id='incident'
                  placeholder='Please state your complaint'
                  value={incident}
                  onChange={(e: any) => setIncident(e.target.value)}
                  required
                  className='w-100 p-3'
                  rows={5}
                ></textarea>
              </div>

              <div className='col text-center'>
                <PrimaryButton type='primary' text='Submit' loading={loading} />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </tbody>
  );
};

export default TransactionTableData;
